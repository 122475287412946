{
    "name": "cc_web_client",
    "version": "1.6.63",
    "private": true,
    "dependencies": {
        "@babel/preset-typescript": "^7.22.5",
        "@doodle3d/clipper-js": "^1.0.11",
        "@emotion/react": "^11.11.4",
        "@emotion/styled": "^11.9.3",
        "@formkit/auto-animate": "^0.8.2",
        "@fortawesome/fontawesome-svg-core": "^6.1.1",
        "@fortawesome/free-regular-svg-icons": "^6.1.1",
        "@fortawesome/free-solid-svg-icons": "^6.6.0",
        "@fortawesome/react-fontawesome": "^0.1.18",
        "@hookform/resolvers": "^3.9.0",
        "@internationalized/date": "^3.3.0",
        "@internationalized/number": "^3.1.1",
        "@mui/material": "^5.9.2",
        "@radix-ui/react-avatar": "^1.1.0",
        "@radix-ui/react-checkbox": "^1.1.1",
        "@radix-ui/react-dialog": "^1.1.1",
        "@radix-ui/react-dropdown-menu": "^2.1.1",
        "@radix-ui/react-icons": "^1.3.0",
        "@radix-ui/react-label": "^2.1.0",
        "@radix-ui/react-popover": "^1.1.1",
        "@radix-ui/react-progress": "^1.0.3",
        "@radix-ui/react-radio-group": "^1.1.3",
        "@radix-ui/react-scroll-area": "^1.1.0",
        "@radix-ui/react-select": "^2.1.1",
        "@radix-ui/react-separator": "^1.1.0",
        "@radix-ui/react-slider": "^1.1.2",
        "@radix-ui/react-slot": "^1.1.0",
        "@radix-ui/react-tabs": "^1.1.0",
        "@radix-ui/react-toggle": "^1.1.0",
        "@radix-ui/react-toggle-group": "^1.1.0",
        "@radix-ui/react-tooltip": "^1.1.2",
        "@react-hook/hover": "^4.0.0",
        "@react-hook/media-query": "^1.1.1",
        "@react-hook/mouse-position": "^4.1.3",
        "@react-hook/resize-observer": "^1.2.6",
        "@rooks/use-outside-click": "^4.11.2",
        "@ryki/datemath": "^1.3.4",
        "@sentry/react": "^7.73.0",
        "@sindresorhus/is": "4.6.0",
        "@skoging/react-app-rewire-source-map-loader": "^1.0.9",
        "@tanstack/react-query": "^4.18.0",
        "@tanstack/react-query-devtools": "^4.29.6",
        "@tanstack/react-router": "1.34.9",
        "@tanstack/react-table": "^8.19.3",
        "@tanstack/router-cli": "^1.33.12",
        "@trpc/client": "^10.45.2",
        "@trpc/react": "^9.27.4",
        "@trpc/server": "^10.45.2",
        "add": "^2.0.6",
        "antd": "^4.7.3",
        "axios": "^0.19.0",
        "axios-request-handler": "^1.0.4",
        "bind-decorator": "^1.0.11",
        "change-case": "^4.1.2",
        "class-variance-authority": "^0.7.0",
        "classnames": "^2.2.5",
        "clsx": "^2.1.1",
        "cmdk": "^1.0.0",
        "copy-to-clipboard": "^3.3.1",
        "core-js": "^3.36.1",
        "create-react-class": "^15.6.3",
        "cuid": "^2.1.8",
        "d3": "^3.5.17",
        "date-fns": "^3.6.0",
        "decircular": "^1.0.0",
        "downloadjs": "^1.4.7",
        "element-resize-detector": "^1.2.3",
        "fix-date": "^1.1.6",
        "flux": "^2.1.1",
        "free-email-domains-list": "^1.0.14",
        "fuse.js": "^7.0.0",
        "get-user-locale": "^1.5.1",
        "globalize": "^0.1.1",
        "hamjest": "^2.13.0",
        "history": "^1.17.0",
        "husl": "^6.0.1",
        "immutable": "^3.8.1",
        "immutable-tuple": "^0.4.10",
        "install": "^0.13.0",
        "iso-lang-codes": "^2.1.1",
        "jquery": "^3.4.1",
        "js-cookie": "^3.0.1",
        "js-file-download": "^0.4.8",
        "locale-currency": "^0.0.2",
        "localforage": "^1.10.0",
        "lodash.merge": "^4.3.5",
        "logrocket": "^1.2.2",
        "lucide-react": "^0.407.0",
        "mathjs": "^6.2.1",
        "mobx": "^6.0.1",
        "mobx-react": "^7.0.0",
        "mobx-react-lite": "^3.0.1",
        "mobx-utils": "^6.0.5",
        "mockdate": "^1.0.4",
        "moment": "^2.14.1",
        "mousetrap": "^1.6.1",
        "nearley": "^2.20.1",
        "next-themes": "^0.3.0",
        "npm": "^3.9.0",
        "numeral": "^2.0.6",
        "papaparse": "^4.3.6",
        "pluralize": "^8.0.0",
        "posthog-js": "^1.149.0",
        "prop-types": "^15.7.2",
        "pusher-js": "^8.3.0",
        "react": "^18.2.0",
        "react-addons-linked-state-mixin": "^15.0.2",
        "react-addons-pure-render-mixin": "^15.4.2",
        "react-addons-transition-group": "^15.3.2",
        "react-aria": "^3.19.0",
        "react-calendar": "^3.1.0",
        "react-data-grid": "^6.1.0",
        "react-day-picker": "^9.0.9",
        "react-dom": "^18.2.0",
        "react-draggable-list": "^4.1.0",
        "react-error-boundary": "^3.1.4",
        "react-hook-form": "^7.52.1",
        "react-input-autosize": "^1.1.0",
        "react-promise-suspense": "^0.3.3",
        "react-reveal": "^1.2.2",
        "react-router": "^3.0.2",
        "react-scripts": "3.1.1",
        "react-select": "^5.4.0",
        "react-sortable-hoc": "^2.0.0",
        "react-spring": "^9.7.4",
        "react-tap-event-plugin": "^1.0.0",
        "react-textarea-autosize": "^4.0.1",
        "react-tooltip": "^5.19.0",
        "react-use-gesture": "^7.0.16",
        "react-widgets": "^3.4.5",
        "reselect": "^2.5.3",
        "rsvp": "^3.2.1",
        "sass": "^1.48.0",
        "slsx": "^0.0.3",
        "sonner": "^1.5.0",
        "superjson": "^1.9.1",
        "tailwind-merge": "^2.4.0",
        "tailwindcss-animate": "^1.0.7",
        "title-case": "^3.0.3",
        "trackjs": "^3.9.2",
        "typescript": "^5.5.4",
        "underscore": "^1.8.3",
        "validator": "^13.12.0",
        "velocity-animate": "^1.2.3",
        "vinyl-source-stream": "^1.1.0",
        "wait": "^0.4.2",
        "wouter": "^2.8.0-alpha.2",
        "xspans": "^1.0.1",
        "xstate": "^5.6.2",
        "yarn": "^1.22.19",
        "zod": "^3.23.8",
        "zustand": "^4.5.0"
    },
    "devDependencies": {
        "@babel/core": "^7.12.0",
        "@babel/plugin-proposal-class-properties": "^7.18.6",
        "@babel/plugin-proposal-decorators": "^7.4.4",
        "@babel/plugin-proposal-logical-assignment-operators": "^7.2.0",
        "@babel/plugin-proposal-nullish-coalescing-operator": "^7.4.4",
        "@babel/plugin-proposal-optional-chaining": "^7.2.0",
        "@babel/plugin-proposal-record-and-tuple": "^7.18.6",
        "@babel/preset-env": "^7.22.9",
        "@babel/preset-react": "^7.22.5",
        "@bloomberg/record-tuple-polyfill": "^0.0.4",
        "@emotion/babel-plugin": "^11.11.0",
        "@parcel/packager-raw-url": "2.12.0",
        "@parcel/transformer-sass": "2.12.0",
        "@parcel/transformer-webmanifest": "2.12.0",
        "@tanstack/router-devtools": "^1.31.26",
        "@types/react": "^18.3.3",
        "@types/react-dom": "^18.3.0",
        "autoprefixer": "^10.4.18",
        "babel-loader": "8.0.6",
        "customize-cra": "^0.2.13",
        "enzyme": "^2.7.1",
        "json": "^9.0.4",
        "parcel": "^2.12.0",
        "postcss": "^8.4.35",
        "qunitjs": "^2.0.0-rc1",
        "react-addons-test-utils": "^15.4.2",
        "react-app-rewired": "^2.1.3",
        "tailwindcss": "^3.4.1"
    },
    "source": "./public/index.html",
    "scripts": {
        "start": "parcel --port 3000",
        "build": "parcel build --dist-dir build"
    },
    "parcel": {
        "extends": "@parcel/config-default",
        "transformers": {
            "*.txt": [
                "...",
                "@parcel/transformer-raw"
            ]
        },
        "contentHash": {
            "txt": false
        }
    },
    "eslintConfig": {
        "extends": "react-app"
    },
    "browserslist": {
        "production": [
            ">0.2%",
            "not dead",
            "not op_mini all"
        ],
        "development": [
            "last 1 chrome version",
            "last 1 firefox version",
            "last 1 safari version"
        ]
    },
    "engines": {
        "node": ">=18.17.0"
    }
}
